define('itdiary/app', ['exports', 'ember', 'itdiary/resolver', 'ember-load-initializers', 'itdiary/config/environment', 'ember-localstorage-adapter'], function (exports, _ember, _itdiaryResolver, _emberLoadInitializers, _itdiaryConfigEnvironment, _emberLocalstorageAdapter) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _itdiaryConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _itdiaryConfigEnvironment['default'].podModulePrefix,
    Resolver: _itdiaryResolver['default']
  });

  App.ApplicationAdapter = _emberLocalstorageAdapter['default'].extend({
    namespace: 'itdiary'
  });

  (0, _emberLoadInitializers['default'])(App, _itdiaryConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});