define('itdiary/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var entries = [];
      var entry = this.store.findAll('entry');
      entry.then(function (values) {
        values.content.forEach(function (ent) {
          var i = ent.getRecord();
          var myEntry = {
            id: i.get('id'),
            title: i.get('title'),
            start: i.get('start'),
            content: i.get('content')
          };
          entries.push(myEntry);
        });
      });
      return entries;
    }
  });
});