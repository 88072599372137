define('itdiary/controllers/index', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      dayClicked: function dayClicked(date) {
        // transform momentjs date to date object for datepicker
        this.set('formDate', date.toDate());
        this.set('addEntryModal', true);
      },
      eventClicked: function eventClicked(event) {
        this.set('eventData', event);
        this.set('eventData.formattedDate', event.start.format('DD.MM.YYYY'));
        this.set('eventData.dateObject', event.start.toDate());
        this.set('showEntryModal', true);
      },
      saveExistingEntry: function saveExistingEntry() {
        // check for valid date
        if (this.get('eventData').dateObject !== (null && undefined && "")) {
          var eventData = this.get('eventData');
          var existingEntry = this.store.findRecord('entry', this.get('eventData').id);
          var newDate = (0, _moment['default'])(eventData.dateObject).format('YYYY-MM-DD');
          existingEntry.then(function (entry) {
            entry.set('start', newDate);
            entry.set('title', eventData.title);
            entry.set('content', eventData.content);
            entry.save();
          });

          // display event in calendar
          // workaraound with new array because of strange bug...
          var myArray = this.get('model').filter(function (evt) {
            return evt.id !== eventData.id;
          });
          var calendarEntry = {
            id: eventData.id,
            title: eventData.title,
            start: newDate,
            content: eventData.content
          };
          myArray.push(calendarEntry);
          this.set('model', myArray);

          var entriesArray = this.get('model').filter(function (evt) {
            return evt;
          });
          this.get('fullCalendar').set('events', entriesArray);

          // reset variables
          this.set('eventData', '');
          this.set('editEntryModal', false);
          this.set('invalidDate', false);
        } else {
          this.set('invalidDate', true);
        }
      },
      closedModal: function closedModal() {
        this.set('showEntryModal', false);
        this.set('addEntryModal', false);
        this.set('editEntryModal', false);

        // reset possible variables
        this.set('invalidDate', false);
        this.set('eventData', '');
        this.set('formDate', '');
        this.set('formTitle', '');
        this.set('formContent', '');
      },
      closedDeleteEntryConfirmModal: function closedDeleteEntryConfirmModal() {
        this.set('showDeleteConfirmModal', false);
        this.set('showEntryModal', true);
      },
      showEditModal: function showEditModal() {
        this.set('showEntryModal', false);
        this.set('editEntryModal', true);
      },
      saveNewEntry: function saveNewEntry() {
        // check for valid date
        if (this.get('formDate') !== (null && undefined && "")) {
          var newDate = (0, _moment['default'])(this.get('formDate')).format('YYYY-MM-DD');
          var newEntry = this.store.createRecord('entry', {
            start: newDate,
            title: this.get('formTitle'),
            content: this.get('formContent')
          });
          newEntry.save();
          var calendarEntry = {
            id: newEntry.get('id'),
            title: newEntry.get('title'),
            start: newEntry.get('start'),
            content: newEntry.get('content')
          };
          this.model.push(calendarEntry);

          // display new event in calendar
          // workaraound with new array because of strange bug...
          var entriesArray = this.get('model').filter(function (evt) {
            return evt;
          });
          this.get('fullCalendar').set('events', entriesArray);

          // reset variables
          this.set('formTitle', '');
          this.set('formContent', '');
          this.set('eventData', '');
          this.set('formDate', '');
          this.set('invalidDate', false);
          this.set('addEntryModal', false);
        } else {
          this.set('invalidDate', true);
        }
      },
      deleteEntryConfirm: function deleteEntryConfirm() {
        this.set('showEntryModal', false);
        this.set('showDeleteConfirmModal', true);
      },
      deleteEntry: function deleteEntry() {
        this.set('showDeleteConfirmModal', false);
        var eventData = this.get('eventData');
        var existingEntry = this.store.findRecord('entry', eventData.id);
        existingEntry.then(function (entry) {
          entry.deleteRecord();
          entry.save();
        });

        // remove entry from model
        var myArray = this.get('model').filter(function (evt) {
          return evt.id !== eventData.id;
        });

        this.get('fullCalendar').set('events', myArray);
      },
      showNewEntryModal: function showNewEntryModal() {
        this.set('addEntryModal', true);
      }
    }
  });
});