define('itdiary/router', ['exports', 'ember', 'itdiary/config/environment'], function (exports, _ember, _itdiaryConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _itdiaryConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('index', { path: '/' });
  });

  exports['default'] = Router;
});